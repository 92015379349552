import {
  Box,
  Card,
  Button,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import HeaderButtons from './Buttons';


const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    background-color: transparent;
    justify-content: right;
    display: flex;
    align-items: center;
     ${theme.breakpoints.down('sm')} {
      height: 10vh; /* Cambia este valor según el tamaño que desees para móviles */
    }
`
);


const HeaderWrapperHome = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    background-color: transparent;
    justify-content: left;
    display: flex;
    align-items: center;
     ${theme.breakpoints.down('sm')} {
      height: 10vh; /* Cambia este valor según el tamaño que desees para móviles */
    }
`
);

const Wrapper= styled(Card)(
  () => `
    backdrop-filter: blur(3px);
    border-radius: 0;
`
);

// const WrapperV2= styled(Card)(
//   () => `

//     border-radius: 0;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
// `
// );


function Header({quien}) {
  const isMobile = useMediaQuery("(max-width:768px)"); // Determina si es una pantalla móvil

  const renderHeaderContent = () => {

    if (quien === "About_us") {
      return (
        <div
          style={{
            width: "100%",
            height: isMobile ? "250px" : "435px",
            backgroundImage: "url(https://media.bclassclient.com/clases_bg_hero.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            textAlign: "center",
            backgroundPosition: "center center",
          }}
        >
          {/* <WrapperV2
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <HeaderWrapperHome elevation={0}>
              <Button
                variant="contained"
                href="/"
                size="medium"
                style={{
                  marginRight: "10px",
                  marginLeft: "25px",
                  fontSize: "14px",
                }}
              >
                INICIO
              </Button>
            </HeaderWrapperHome>
            <HeaderWrapper elevation={0}>
              <HeaderButtons qu={quien} />
            </HeaderWrapper>
          </WrapperV2> */}
           <Wrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFDD00", // Aqui deberiamos llamar al API de colores
          }}
        >
          <HeaderWrapperHome elevation={0}>
            <Button
              variant="contained"
              href="/"
              size="medium"
              style={{
                marginRight: "10px",
                marginLeft: isMobile ? "10px" : "20px",
                fontSize: "14px",
              }}
            >
              INICIO
            </Button>
          </HeaderWrapperHome>
          <HeaderWrapper elevation={0}>
            <HeaderButtons qu={quien} />
          </HeaderWrapper>
        </Wrapper>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontSize: isMobile ? "24px" : "45px",
                fontWeight: "bolder",
              }}
              sx={{ mt: 8 }}
            >
              Indoor Cycling por BCLASS
            </Typography>
            <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  color: "white",
                  fontSize: isMobile ? "14px" : "20px",
                  width: isMobile ? "90%" : "600px",
                  fontWeight: "lighter",
                }}
                sx={{ mt: 1 }}
              >
                Los entrenamientos de ciclismo en interiores mejoran su condición física
                cardiovascular y lo empujan más lejos que nunca. Prepárate para montar.
              </Typography>
            </Box>
          </Box>
        </div>
      );
    } else if (quien === "Plan") {
      return (
        <div
          style={{
            width: "100%",
            height: isMobile ? "200px" : "360px",
            backgroundImage: "url(https://media.bclassclient.com/planes_plan.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            textAlign: "center",
            backgroundPosition: "center center",
          }}
        >
          {/* <WrapperV2
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <HeaderWrapperHome elevation={0}>
              <Button
                variant="contained"
                href="/"
                size="medium"
                style={{
                  marginRight: "10px",
                  marginLeft: "25px",
                  fontSize: "14px",
                }}
              >
                INICIO
              </Button>
            </HeaderWrapperHome>
            <HeaderWrapper elevation={0}>
              <HeaderButtons qu={quien} />
            </HeaderWrapper>
          </WrapperV2> */}
           <Wrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFDD00", // Aqui deberiamos llamar al API de colores
          }}
        >
          <HeaderWrapperHome elevation={0}>
            <Button
              variant="contained"
              href="/"
              size="medium"
              style={{
                marginRight: "10px",
                marginLeft: isMobile ? "10px" : "20px",
                fontSize: "14px",
              }}
            >
              INICIO
            </Button>
          </HeaderWrapperHome>
          <HeaderWrapper elevation={0}>
            <HeaderButtons qu={quien} />
          </HeaderWrapper>
        </Wrapper>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontSize: isMobile ? "24px" : "45px",
                fontWeight: "bolder",
              }}
              sx={{ mt: 8 }}
            >
              Nuestros Planes
            </Typography>
            <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  color: "white",
                  fontSize: isMobile ? "14px" : "20px",
                  width: isMobile ? "90%" : "600px",
                  fontWeight: "lighter",
                }}
                sx={{ mt: 1 }}
              >
                La motivación es lo que te pone en marcha, y el hábito es lo que hace que sigas.
              </Typography>
            </Box>
          </Box>
        </div>
      );
    } else if (quien === "Clases" || quien === "Perfil") {
      return (
        <Wrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFDD00", // Aqui deberiamos llamar al API de colores
          }}
        >
          <HeaderWrapperHome elevation={0}>
            <Button
              variant="contained"
              href="/"
              size="medium"
              style={{
                marginRight: "10px",
                marginLeft: isMobile ? "10px" : "20px",
                fontSize: "14px",
              }}
            >
              INICIO
            </Button>
          </HeaderWrapperHome>
          <HeaderWrapper elevation={0}>
            <HeaderButtons qu={quien} />
          </HeaderWrapper>
        </Wrapper>
      );
    }
     return null;
  };
  return (
    <>{renderHeaderContent()}</>
  );
}

export default Header;
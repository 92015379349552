import axios from 'axios';
import { backendURL as baseUrl } from 'src/config';
// import { localURL as baseUrl} from 'src/config';


const certifyAxios = axios.create({
  baseURL: baseUrl
})

const certifyAxiosImages = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
  }
})

// const certifyPOS = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization":"Basic OTQ2ODAxMjI6dGVzdHBhc3N3b3JkXzRwWUYwVldmdHNXTGhJUzdrZERMOE5oclFVbG9GcEViVjQycmVwU2ZlZ3A0Sw=="
//   }
// })


certifyAxios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

certifyAxiosImages.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);


// certifyPOS.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || 'There is an error!'
//     )
// );

export default certifyAxios;

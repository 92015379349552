import {
  Grid,
  Box,
  Button,
  Link,
  Typography,
  styled,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import logo from "../../layouts/AccentHeaderLayout/Header/Logo/Logo.svg";

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#FFDD00",
        px: { xs: 2, sm: 5 }, // Padding horizontal responsivo
        py: 4, // Padding vertical
        mt: "auto",
      }}
    >
      {/* Sección superior */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" }, // Cambia de columna en móviles a fila en pantallas grandes
        }}
      >
        <Button
          variant="contained"
          href="/"
          size="medium"
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            mb: { xs: 2, sm: 0 }, // Espaciado inferior en móviles
          }}
        >
          INICIO
        </Button>
        <img src={logo} alt="Logo" style={{ height: "83px" }} />
      </Box>

      {/* Línea divisora */}
      <Box
        component="hr"
        sx={{
          my: 2,
          borderColor: "black",
          borderStyle: "solid",
        }}
      />

      {/* Sección de contenido */}
      <Grid container spacing={2}>
        {/* Contacto */}
        <Grid item xs={12} sm={3}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
            CONTACTO
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "black", mt: 1 }}>
            perubclass@gmail.com
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "black" }}>
            923 793 549
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "black" }}>
            Av. Primavera 770 - Chacarilla del Estanque - Surco
          </Typography>
        </Grid>

        {/* Horarios */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
            HORARIOS
          </Typography>
          <Typography
            sx={{ fontSize: "15px", color: "black", mt: 1, textAlign: "left" }}
          >
            Lunes - Viernes 06:00 AM a 09:00 PM / Sábado y Domingo 09:00 AM a
            01:00 PM
          </Typography>
        </Grid>

        {/* Sobre Nosotros */}
        <Grid item xs={12} sm={3}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
            SOBRE NOSOTROS
          </Typography>
          <StyledLink
            href="https://wa.me/51923793549"
            target="_blank"
            sx={{ display: "block", mt: 1, color: "black", fontWeight: "bold" }}
          >
            CHATEA CON NOSOTROS
          </StyledLink>
          <StyledLink
            href="/"
            sx={{ display: "block", mt: 1, color: "black", fontWeight: "bold" }}
          >
            QUIENES SOMOS
          </StyledLink>
          <StyledLink
            href="https://media.bclassclient.com/TermsAndConditionsBClass_07_2024.pdf"
            target="_blank"
            sx={{ display: "block", mt: 1, color: "black", fontWeight: "bold" }}
          >
            TÉRMINOS Y CONDICIONES
          </StyledLink>
          <StyledLink
            href="/"
            sx={{ display: "block", mt: 1, color: "black", fontWeight: "bold" }}
          >
            POLÍTICAS DE PRIVACIDAD
          </StyledLink>
        </Grid>

        {/* Redes Sociales */}
        <Grid item xs={12} sm={2} textAlign="center">
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
            SÍGUENOS EN
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 1,
            }}
          >
            <Link href="https://www.facebook.com/bclassperu/" target="_blank">
              <FacebookIcon sx={{ color: "black", fontSize: 40 }} />
            </Link>
            <Link href="https://www.instagram.com/bclassperu/" target="_blank">
              <InstagramIcon sx={{ color: "black", fontSize: 40 }} />
            </Link>
            <Link href="https://wa.me/51923793549" target="_blank">
              <WhatsAppIcon sx={{ color: "black", fontSize: 40 }} />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
